import * as Types from '../mutationTypes';
import HestiaApi  from '@/api';

// initial state
const state = {
  mandatesCo              : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  },
  currentMandateCo        : {},
  currentMandateCoProgram : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  }
};

// getters
const getters = {
  mandatesCo              : state => state.mandatesCo,
  currentMandateCo        : state => state.currentMandateCo,
  currentMandateCoProgram : state => state.currentMandateCoProgram
};

// actions
const actions = {
  // MANDATE
  async getMandatesCo({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Commercialisation.mandate.list(tenantId, { searchParams : query }).json();

    commit(Types.RECEIVE_MANDATESCO, response);
  },
  async addMandateCo({ commit }, { tenantId, mandateCo }) {

    const response = await HestiaApi.Commercialisation.mandate.create(tenantId, mandateCo).json();
    commit(Types.ADD_MANDATECO, response);
    return response;
  },
  async editMandateCo({ commit }, { tenantId, id, mandateCo }) {

    const mandateCommercialisation = await HestiaApi.Commercialisation.mandate.update(tenantId, id, mandateCo).json();
    mandateCommercialisation.avenants = await Promise.all(mandateCommercialisation.avenants.map((a) => {

      return HestiaApi.Document.document.get(tenantId, a).json();
    }));

    commit(Types.EDIT_MANDATECO, mandateCommercialisation);
  },
  async selectMandateCo({ commit }, { tenantId, id }) {

    const mandateCo = await HestiaApi.Commercialisation.mandate.get(tenantId, id).json();

    // Re format all avenant, we want more infos than only the id
    mandateCo.avenants = await Promise.all(mandateCo.avenants.map((a) => {

      return HestiaApi.Document.document.get(tenantId, a).json();
    }));

    commit(Types.SELECT_MANDATE_CO, mandateCo);
  },
  async addAvenantMandateCo({ commit }, { tenantId, id, avenant }) {

    await HestiaApi.Commercialisation.mandate.addAvenant(tenantId, id, { avenant : avenant });
    const document = await HestiaApi.Document.document.get(tenantId, avenant).json();
    commit(Types.ADD_MANDATECO_AVENANT, document);
  },
  async deleteAvenantMandateCo({ commit }, { tenantId, mandateCoId, avenantId }) {

    await HestiaApi.Commercialisation.mandate.deleteAvenant(tenantId, mandateCoId, avenantId);

    commit(Types.REMOVE_MANDATECO_AVENANT, avenantId);
  },
  async getMandatePrograms({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Commercialisation.program.list(tenantId, { searchParams : query }).json();

    commit(Types.RECEIVE_MANDATECO_PROGRAMS, response);
  },
  async editMandatecoOwner({ commit }, { tenantId, id, owner, editCurrentMandateCo = true }) {

    const response = await HestiaApi.Commercialisation.mandate.editOwner(tenantId, id, owner).json();

    if (editCurrentMandateCo) {

      commit(Types.EDIT_MANDATECO, response);
    }
  },
  async addMandatecoCollaborator({ commit }, { tenantId, id, collaborator, editCurrentMandateCo = true }) {

    const response = await HestiaApi.Commercialisation.mandate.addCollaborator(tenantId, id, collaborator).json();

    if (editCurrentMandateCo) {

      commit(Types.EDIT_MANDATECO, response);
    }
  },
  async addMandatecoCoowner({ commit }, { tenantId, id, coowner, editCurrentMandateCo = true }) {

    const response = await HestiaApi.Commercialisation.mandate.addCoOwner(tenantId, id, coowner).json();

    if (editCurrentMandateCo) {

      commit(Types.EDIT_MANDATECO, response);
    }
  },
  async removeMandatecoCollaborator({ commit }, { tenantId, id, collaborator, editCurrentMandateCo = true }) {

    await HestiaApi.Commercialisation.mandate.removeCollaborator(tenantId, id, collaborator);

    if (editCurrentMandateCo) {

      commit(Types.REMOVE_MANDATECO_COLLABORATOR, collaborator);
    }
  },
  async removeMandatecoCoowner({ commit }, { tenantId, id, coowner, editCurrentMandateCo = true }) {

    await HestiaApi.Commercialisation.mandate.removeCoOwner(tenantId, id, coowner);

    if (editCurrentMandateCo) {

      commit(Types.REMOVE_MANDATECO_COOWNER, coowner);
    }
  }
};

// mutations
const mutations = {
  [Types.RECEIVE_MANDATESCO](state, mandatesCo) {

    state.mandatesCo = mandatesCo;
  },
  [Types.SELECT_MANDATE_CO](state, mandate) {

    state.currentMandateCo = mandate;
  },
  [Types.ADD_MANDATECO](state, mandate) {

    state.mandatesCo.results.push(mandate);
  },
  [Types.EDIT_MANDATECO](state, mandate) {

    state.currentMandateCo = mandate;
  },
  [Types.ADD_MANDATECO_AVENANT](state, avenant) {

    state.currentMandateCo.avenants.push(avenant);
  },
  [Types.RECEIVE_MANDATECO_PROGRAMS](state, mandateProgram) {

    state.currentMandateCoProgram = mandateProgram;
  },
  [Types.REMOVE_MANDATECO_AVENANT](state, avenantId) {

    const index = state.currentMandateCo.avenants.findIndex((avenant) => avenant.id === avenantId);
    state.currentMandateCo.avenants.splice(index, 1);
  },
  [Types.REMOVE_MANDATECO_COLLABORATOR](state, collaborator) {

    const index = state.currentMandateCo.collaborators.findIndex((c) => c.id === collaborator.id);
    state.currentMandateCo.collaborators.splice(index, 1);
  },
  [Types.REMOVE_MANDATECO_COOWNER](state, coowner) {

    const index = state.currentMandateCo.coOwners.findIndex((c) => c.id === coowner.id);
    state.currentMandateCo.coOwners.splice(index, 1);
  }

};

export default {
  state,
  getters,
  actions,
  mutations
};
