import * as Types  from '../mutationTypes';
import ObjectUtils from '@/utils/object';

function getDefaultState(model) {

  let pagination = { page : 1, perPage : 10 };
  let sort = 'updated_at_desc';

  if (model === 'request' || model === 'access' || model === 'property_request' || model === 'timemachine' || model === 'timemachine_user' || model === 'offer' || model === 'wallet_transaction') {
    sort = 'created_at_desc';
  }

  if (model === 'property_feed') {
    sort = 'publication_date';
  }

  if (model === 'property_type' || model === 'property_primary_type' || model === 'property_secondary_type' || model === 'company_client') {
    sort = 'order_asc';
    pagination = {};
  }

  if (model === 'appointment' || model === 'client_appointment' || model === 'property_appointment') {
    sort = 'start_at_desc';
  }

  if (model === 'mandate_property' || model === 'property_document_type' || model === 'mandate_document_type' || model === 'client_document_type' || model === 'company_document_type' || model === 'eligibility') {
    sort = '';
  }

  if (model === 'mandate_type' || model === 'mandate_source' || model === 'property_feature' || model === 'property_category') {
    sort = 'order_asc';
  }

  if (model === 'avenant' || model === 'mandate_avenant') {
    sort = 'number_desc';
  }

  if (model === 'request_client' || model === 'client') {
    sort = 'relevance';
  }

  if (model === 'similar_request') {
    sort = 'status';
  }

  if (model === 'registry_mandate') {
    sort = 'date_mandat_desc';
  }

  if (model === 'thread_template' || model === 'notification') {
    sort = '';
  }

  if (model === 'agent') {
    pagination = { page : 1, perPage : 12 };
  }

  if (model === 'agent_map' || model === 'property_feed') {
    pagination = { page : 1, perPage : 1000 };
  }

  if (model === 'agency') {
    pagination = { page : 1, perPage : 12 };
  }

  if (model === 'agency_map') {
    pagination = { page : 1, perPage : 1000 };
  }

  return {
    search     : {},
    sort       : sort,
    pagination : pagination,
    model      : model
  };
}

// initial state
const state = {
  'mandate'                  : getDefaultState('mandate'),
  'client_mandate'           : getDefaultState('client_mandate'),
  'company_mandate'          : getDefaultState('company_mandate'),
  'mandate_type'             : getDefaultState('mandate_type'),
  'request_source'           : getDefaultState('request_source'),
  'request_received'         : getDefaultState('request_received'),
  'request_inbounds'         : getDefaultState('request_inbounds'),
  'avenant'                  : getDefaultState('avenant'),
  'property'                 : getDefaultState('property'),
  'property_map'             : getDefaultState('property_map'),
  'property_feature'         : getDefaultState('property_feature'),
  'property_category'        : getDefaultState('property_category'),
  'property_type'            : getDefaultState('property_type'),
  'property_primary_type'    : getDefaultState('property_primary_type'),
  'property_secondary_type'  : getDefaultState('property_secondary_type'),
  'property_document_type'   : getDefaultState('property_document_type'),
  'mandate_property'         : getDefaultState('mandate_property'),
  'mandate_avenant'          : getDefaultState('mandate_avenant'),
  'mandate_document_type'    : getDefaultState('mandate_document_type'),
  'registry_mandate'         : getDefaultState('registry_mandate'),
  'property_match'           : getDefaultState('property_match'),
  'client_match'             : getDefaultState('client_match'),
  'client_search'            : getDefaultState('client_search'),
  'client'                   : getDefaultState('client'),
  'client_document_type'     : getDefaultState('client_document_type'),
  'similar_client'           : getDefaultState('similar_client'),
  'company'                  : getDefaultState('company'),
  'request'                  : getDefaultState('request'),
  'similar_request'          : getDefaultState('similar_request'),
  'thread'                   : getDefaultState('thread'),
  'thread_template'          : getDefaultState('thread_template'),
  'property_offer'           : getDefaultState('property_offer'),
  'property_booking'         : getDefaultState('property_booking'),
  'client_offer'             : getDefaultState('client_offer'),
  'property_issue'           : getDefaultState('property_issue'),
  'mandate_issue'            : getDefaultState('mandate_issue'),
  'client_issue'             : getDefaultState('client_issue'),
  'company_issue'            : getDefaultState('company_issue'),
  'appointment'              : getDefaultState('appointment'),
  'client_appointment'       : getDefaultState('client_appointment'),
  'client_discussion'        : getDefaultState('client_discussion'),
  'property_request'         : getDefaultState('property_request'),
  'property_appointment'     : getDefaultState('property_appointment'),
  'property_feed'            : getDefaultState('property_feed'),
  'user'                     : getDefaultState('user'),
  'agent'                    : getDefaultState('agent'),
  'agent_map'                : getDefaultState('agent_map'),
  'group'                    : getDefaultState('group'),
  'access'                   : getDefaultState('access'),
  'developer'                : getDefaultState('developer'),
  'notification'             : getDefaultState('notification'),
  'program'                  : getDefaultState('program'),
  'offer'                    : getDefaultState('offer'),
  'lot'                      : getDefaultState('lot'),
  'booking'                  : getDefaultState('booking'),
  'mandate_program'          : getDefaultState('mandate_program'),
  'mandate_co'               : getDefaultState('mandate_co'),
  'timemachine'              : getDefaultState('timemachine'),
  'timemachine_user'         : getDefaultState('timemachine_user'),
  'eligibility'              : getDefaultState('eligibility'),
  'contact'                  : getDefaultState('contact'),
  'program_lot'              : getDefaultState('program_lot'),
  'developer_mandate_co'     : getDefaultState('developer_mandate_co'),
  'developer_branch'         : getDefaultState('developer_branch'),
  'developer_branch_contact' : getDefaultState('developer_branch_contact'),
  'contact_program'          : getDefaultState('contact_program'),
  'company_client'           : getDefaultState('company_client'),
  'client_company'           : getDefaultState('client_company'),
  'request_client'           : getDefaultState('request_client'),
  'agency'                   : getDefaultState('agency'),
  'agency_map'               : getDefaultState('agency_map'),
  'importer'                 : getDefaultState('importer'),
  'commercialisationClients' : getDefaultState('commercialisationClients'),
  'partner'                  : getDefaultState('partner'),
  'partner_property'         : getDefaultState('partner_property'),
  'audit_event'              : getDefaultState('audit_event'),
  'flag'                     : getDefaultState('flag'),
  'company_document_type'    : getDefaultState('company_document_type'),
  'mailing_template'         : getDefaultState('mailing_template'),
  'wallet_transaction'       : getDefaultState('wallet_transaction')
};

// gettersp
const getters = {
  query                         : state => state,
  queryMandate                  : state => state.mandate,
  queryClientMandate            : state => state.client_mandate,
  queryCompanyMandate           : state => state.company_mandate,
  queryMandateType              : state => state.mandate_type,
  queryRequestSource            : state => state.request_source,
  queryRequestInbound           : state => state.request_inbounds,
  queryRequestReceived          : state => state.request_received,
  queryAvenant                  : state => state.avenant,
  queryProperty                 : state => state.property,
  queryPropertyMap              : state => state.property_map,
  queryPropertyFeature          : state => state.property_feature,
  queryPropertyCategory         : state => state.property_category,
  queryPropertyType             : state => state.property_type,
  queryPrimaryPropertyType      : state => state.property_primary_type,
  queryPropertyDocumentType     : state => state.property_document_type,
  querySecondaryPropertyType    : state => state.property_secondary_type,
  queryMandateProperty          : state => state.mandate_property,
  queryMandateAvenant           : state => state.mandate_avenant,
  queryMandateDocumentType      : state => state.mandate_document_type,
  queryRegistryMandate          : state => state.registry_mandate,
  queryPropertyMatch            : state => state.property_match,
  queryClientMatch              : state => state.client_match,
  queryClientSearch             : state => state.client_search,
  queryClient                   : state => state.client,
  queryClientDocumentType       : state => state.client_document_type,
  querySimilarClient            : state => state.similar_client,
  queryCompany                  : state => state.company,
  queryRequest                  : state => state.request,
  querySimilarRequest           : state => state.similar_request,
  queryThread                   : state => state.thread,
  queryThreadTemplate           : state => state.thread_template,
  queryPropertyOffer            : state => state.property_offer,
  queryPropertyBooking          : state => state.property_booking,
  queryClientOffer              : state => state.client_offer,
  queryPropertyIssue            : state => state.property_issue,
  queryMandateIssue             : state => state.mandate_issue,
  queryClientIssue              : state => state.client_issue,
  queryCompanyIssue             : state => state.company_issue,
  queryAppointment              : state => state.appointment,
  queryClientAppointment        : state => state.client_appointment,
  queryClientDiscussion         : state => state.client_discussion,
  queryPropertyRequest          : state => state.property_request,
  queryPropertyAppointment      : state => state.property_appointment,
  queryPropertyFeed             : state => state.property_feed,
  queryUser                     : state => state.user,
  queryAgent                    : state => state.agent,
  queryAgentMap                 : state => state.agent_map,
  queryGroup                    : state => state.group,
  queryAccess                   : state => state.access,
  queryDeveloper                : state => state.developer,
  queryNotification             : state => state.notification,
  queryContact                  : state => state.contact,
  queryDeveloperMandateCo       : state => state.developer_mandate_co,
  queryDeveloperBranch          : state => state.developer_branch,
  queryProgram                  : state => state.program,
  queryLot                      : state => state.lot,
  queryBooking                  : state => state.booking,
  queryMandateProgram           : state => state.mandate_program,
  queryMandateCo                : state => state.mandate_co,
  queryTimeMachine              : state => state.timemachine,
  queryTimeMachineUser          : state => state.timemachine_user,
  queryEligibility              : state => state.eligibility,
  queryProgramLot               : state => state.program_lot,
  queryDeveloperBranchContact   : state => state.developer_branch_contact,
  queryContactProgram           : state => state.contact_program,
  queryOffer                    : state => state.offer,
  queryCompanyClient            : state => state.company_client,
  queryClientCompany            : state => state.client_company,
  queryRequestClient            : state => state.request_client,
  queryAgency                   : state => state.agency,
  queryAgencyMap                : state => state.agency_map,
  queryImporter                 : state => state.importer,
  queryPartner                  : state => state.partner,
  queryCommercialisationClients : state => state.commercialisationClients,
  queryPartnerProperty          : state => state.partner_property,
  queryAuditEvent               : state => state.audit_event,
  queryFlag                     : state => state.flag,
  queryCompanyDocumentType      : state => state.company_document_type,
  queryMailingTemplate          : state => state.mailing_template,
  queryWalletTransaction        : state => state.wallet_transaction
};

// actions
const actions = {
  updateSearch({ commit }, { model, params }) {

    commit(Types.UPDATE_QUERY_SEARCH, { model, params });
  },
  removeSearch({ commit }, { model, name, value = null, parent = null }) {

    commit(Types.REMOVE_QUERY_SEARCH, { model, name, value, parent });
  },
  updateSort({ commit }, { model, sort, resetPagination = false }) {

    commit(Types.UPDATE_QUERY_SORT, { model, sort, resetPagination });
  },
  updatePagination({ commit }, { model, pagination }) {

    commit(Types.UPDATE_QUERY_PAGINATION, { model, pagination });
  },
  clearQuery({ commit }, { model }) {

    commit(Types.CLEAR_QUERY, { model });
  }
};

// mutations
const mutations = {
  // SEARCH
  [Types.UPDATE_QUERY_SEARCH](state, { model, params }) {

    state[model].search = {
      ...state[model].search,
      ...params
    };

    if ('pagination' in state[model] && 'page' in state[model].pagination) {

      // Reinit to page 1
      state[model].pagination.page = 1;
    }
  },
  [Types.REMOVE_QUERY_SEARCH](state, { model, name, value, parent }) {

    // Search all the keys who start with the same name
    // Exemple : "my_properties" => Boolean / "my_properties_list" => Array
    let toDelete = [];
    if (parent === null) {

      toDelete = Object.keys(state[model].search).filter((key) => {

        return key.startsWith(name);
      });
    } else {

      toDelete = Object.keys(state[model].search[parent]).filter((key) => {

        return key.startsWith(name);
      });
    }

    for (const keyToDelete of toDelete) {

      if (value === null || (state[model].search[keyToDelete].length === 1)) {

        if (parent === null) {

          state[model].search = ObjectUtils.omit(state[model].search, [keyToDelete]);
        } else {

          state[model].search[parent] = ObjectUtils.omit(state[model].search[parent], [keyToDelete]);
        }
      } else {
        // Array CASE
        let index = -1;

        if (typeof value === 'string') {
          // Value is a string

          index = state[model].search[keyToDelete].findIndex((s) => s === value);

        } else if (typeof value === 'object') {
          // Value is an Object (with id)

          index = state[model].search[keyToDelete].findIndex((s) => s.id === value.id);

        }

        state[model].search[keyToDelete].splice(index, 1);
      }
    }

    if ('pagination' in state[model] && 'page' in state[model].pagination) {

      // Reinit to page 1
      state[model].pagination.page = 1;
    }
  },
  [Types.CLEAR_QUERY](state, { model }) {

    state[model] = getDefaultState(model);
  },
  // SORT
  [Types.UPDATE_QUERY_SORT](state, { model, sort, resetPagination }) {

    state[model].sort = sort;
    if (resetPagination) {
      state[model].pagination.page = 1;
    }

  },

  // PAGINATION
  [Types.UPDATE_QUERY_PAGINATION](state, { model, pagination }) {

    if (state[model]) {
      state[model].pagination = pagination;
    }

  },

  [Types.UPDATE_QUERY](state, { model, query }) {

    state[model] = query;
  }

};

export default {
  state,
  getters,
  actions,
  mutations
};
