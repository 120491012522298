import * as Types         from '../mutationTypes';
import HestiaApi          from '@/api';
import ObjectManipulation from '@/utils/object';

// initial state
const state = {
  programs           : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  },
  currentProgramLots : {
    results    : [],
    pagination : {
      perPage : 0,
      page    : 1,
      total   : 0
    }
  },
  currentProgram     : {}
};

// getters
const getters = {
  programs           : state => state.programs,
  currentProgram     : state => state.currentProgram,
  currentProgramLots : state => state.currentProgramLots
};

// actions
const actions = {

  // PROGRAM
  async getPrograms({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Commercialisation.program.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_PROGRAMS, response);
  },
  async addProgram({ commit }, { tenantId, program }) {

    const response = await HestiaApi.Commercialisation.program.create(tenantId, program).json();
    commit(Types.ADD_PROGRAM, response);
    return response;
  },
  async editProgram({ commit }, { id, program, tenantId }) {

    const response = await HestiaApi.Commercialisation.program.update(tenantId, id, program).json();
    commit(Types.EDIT_PROGRAM, response);
  },
  async selectProgram({ commit }, { tenantId, id }) {

    const program = await HestiaApi.Commercialisation.program.get(tenantId, id).json();

    commit(Types.RECEIVE_PROGRAM, program);
  },
  async addProgramImage({ commit }, { tenantId, id, image }) {

    await HestiaApi.Commercialisation.program.addImage(tenantId, id, { image });
    commit(Types.ADD_PROGRAM_IMAGE, image);
  },
  async removeProgramImage({ commit }, { tenantId, programId, imageId }) {

    await HestiaApi.Commercialisation.program.removeImage(tenantId, programId, imageId);
    commit(Types.REMOVE_PROGRAM_IMAGE, imageId);
  },
  async addProgramEligibility({ commit }, { tenantId, id, eligibility }) {

    const response = await HestiaApi.Commercialisation.program.addEligibility(tenantId, id, eligibility).json();
    commit(Types.PROGRAM_ADD_ELIGIBILITY, response);
  },
  async removeProgramEligibility({ commit }, { tenantId, programId, eligibilityId }) {

    await HestiaApi.Commercialisation.program.deleteEligibility(tenantId, programId, eligibilityId);
    commit(Types.PROGRAM_DELETE_ELIGIBILITY, eligibilityId);
  },
  async getProgramLots({ commit }, { tenantId, query }) {

    const response = await HestiaApi.Commercialisation.lot.list(tenantId, { searchParams : query }).json();
    commit(Types.RECEIVE_PROGRAM_LOTS, response);
  },
  async editLot({ commit }, { tenantId, id, lot }) {

    const response = await HestiaApi.Commercialisation.lot.update(tenantId, id, lot).json();
    commit(Types.EDIT_PROGRAM_LOT, response);
  },
  async addLot({ commit }, { tenantId, lot }) {

    const response = await HestiaApi.Commercialisation.lot.create(tenantId, lot).json();
    commit(Types.ADD_PROGRAM_LOT, response);
    return response;
  },
  async addProgramDocument({ commit }, { tenantId, id, document }) {

    await HestiaApi.Commercialisation.program.addDocument(tenantId, id, { document : document.id });
    commit(Types.PROGRAM_ADD_DOCUMENT, { ...document });
  },
  async removeProgramDocument({ commit }, { tenantId, programId, documentId }) {

    await HestiaApi.Commercialisation.program.deleteDocument(tenantId, programId, documentId);
    commit(Types.PROGRAM_DELETE_DOCUMENT, documentId);
  },
  async editProgramOwner({ commit }, { tenantId, id, owner, editCurrentProgram = true }) {

    const response = await HestiaApi.Commercialisation.program.editOwner(tenantId, id, owner).json();

    if (editCurrentProgram) {

      commit(Types.EDIT_PROGRAM, response);
    }
  },
  async addProgramCollaborator({ commit }, { tenantId, id, collaborator, editCurrentProgram = true }) {

    const response = await HestiaApi.Commercialisation.program.addCollaborator(tenantId, id, collaborator).json();

    if (editCurrentProgram) {

      commit(Types.EDIT_PROGRAM, response);
    }
  },
  async addProgramCoowner({ commit }, { tenantId, id, coowner, editCurrentProgram = true }) {

    const response = await HestiaApi.Commercialisation.program.addCoOwner(tenantId, id, coowner).json();

    if (editCurrentProgram) {

      commit(Types.EDIT_PROGRAM, response);
    }
  },
  async removeProgramCollaborator({ commit }, { tenantId, id, collaborator, editCurrentProgram = true }) {

    await HestiaApi.Commercialisation.program.removeCollaborator(tenantId, id, collaborator).json();

    if (editCurrentProgram) {

      commit(Types.REMOVE_PROGRAM_COLLABORATOR, collaborator);
    }
  },
  async removeProgramCoowner({ commit }, { tenantId, id, coowner, editCurrentProgram = true }) {

    await HestiaApi.Commercialisation.program.removeCoOwner(tenantId, id, coowner).json();

    if (editCurrentProgram) {

      commit(Types.REMOVE_PROGRAM_COOWNER, coowner);
    }
  },

  async editLotOwner({ commit }, { tenantId, id, owner, editCurrentLot = true }) {

    const response = await HestiaApi.Commercialisation.lot.editOwner(tenantId, id, owner).json();

    if (editCurrentLot) {

      commit(Types.EDIT_PROGRAM_LOT, response);
    }
  },
  async addLotCollaborator({ commit }, { tenantId, id, collaborator, editCurrentLot = true }) {

    const response = await HestiaApi.Commercialisation.lot.addCollaborator(tenantId, id, collaborator).json();

    if (editCurrentLot) {

      commit(Types.EDIT_PROGRAM_LOT, response);
    }
  },
  async addLotCoowner({ commit }, { tenantId, id, coowner, editCurrentLot = true }) {

    const response = await HestiaApi.Commercialisation.lot.addCoOwner(tenantId, id, coowner).json();

    if (editCurrentLot) {

      commit(Types.EDIT_PROGRAM_LOT, response);
    }
  },
  async removeLotCollaborator({ commit }, { tenantId, id, collaborator, editCurrentLot = true }) {

    await HestiaApi.Commercialisation.lot.removeCollaborator(tenantId, id, collaborator).json();

    if (editCurrentLot) {

      commit(Types.REMOVE_LOT_COLLABORATOR, { collaborator, id });
    }
  },
  async removeLotCoowner({ commit }, { tenantId, id, coowner, editCurrentLot = true }) {

    await HestiaApi.Commercialisation.lot.removeCoOwner(tenantId, id, coowner).json();

    if (editCurrentLot) {

      commit(Types.REMOVE_LOT_COOWNER, { coowner, id });
    }
  }

};

// mutations
const mutations = {
  [Types.RECEIVE_PROGRAMS](state, programs) {

    state.programs = programs;
  },
  [Types.RECEIVE_PROGRAM](state, program) {

    state.currentProgram = program;
  },
  [Types.EDIT_PROGRAM](state, program) {

    state.currentProgram = {
      ...program
    };

    const index = state.programs.results.findIndex((prog) => prog.id === program.id);
    state.programs.results.splice(index, 1, program);
  },
  [Types.ADD_PROGRAM](state, program) {

    state.programs.results.push(program);
  },
  [Types.ADD_PROGRAM_IMAGE](state, image) {

    state.currentProgram.images.push(image);
  },
  [Types.REMOVE_PROGRAM_IMAGE](state, imageId) {

    const index = state.currentProgram.images.findIndex((img) => img === imageId);
    state.currentProgram.images.splice(index, 1);
  },
  [Types.RECEIVE_PROGRAM_LOTS](state, programLots) {

    state.currentProgramLots = programLots;
  },
  [Types.EDIT_PROGRAM_LOT](state, lot) {

    const index = state.currentProgramLots.results.findIndex((l) => l.id === lot.id);
    state.currentProgramLots.results[index] = ObjectManipulation.clone(lot);
    state.currentProgramLots = ObjectManipulation.clone(state.currentProgramLots);

  },
  [Types.ADD_PROGRAM_LOT](state, lot) {

    state.currentProgramLots.results.push(lot);
  },
  [Types.PROGRAM_ADD_DOCUMENT](state, { ...document }) {

    state.currentProgram.documents.push(document.id);
  },
  [Types.PROGRAM_DELETE_DOCUMENT](state, documentId) {

    const index = state.currentProgram.documents.findIndex((docId) => docId === documentId);

    state.currentProgram.documents.splice(index, 1);

  },
  [Types.REMOVE_PROGRAM_COLLABORATOR](state, collaborator) {

    const index = state.currentProgram.collaborators.findIndex((c) => c.id === collaborator.id);
    state.currentProgram.collaborators.splice(index, 1);
  },
  [Types.REMOVE_PROGRAM_COOWNER](state, coowner) {

    const index = state.currentProgram.coOwners.findIndex((c) => c.id === coowner.id);
    state.currentProgram.coOwners.splice(index, 1);
  },

  [Types.REMOVE_LOT_COLLABORATOR](state, infos) {

    const index = state.currentProgramLots.results.findIndex((lot) => lot.id === infos.id);
    const collaboratorIndex = state.currentProgramLots.results[index].collaborators.findIndex((c) => c.id === infos.collaborator.id);
    state.currentProgramLots.results[index].collaborators.splice(collaboratorIndex, 1);
  },
  [Types.REMOVE_LOT_COOWNER](state, infos) {

    const index = state.currentProgramLots.results.findIndex((lot) => lot.id === infos.id);
    const coOwnerIndex = state.currentProgramLots.results[index].coOwners.findIndex((c) => c.id === infos.coowner.id);
    state.currentProgramLots.results[index].coOwners.splice(coOwnerIndex, 1);
  },
  [Types.EDIT_LOT](state, lot) {

    state.currentProgramLots = {
      ...lot
    };

    const index = state.currentProgramLots.results.findIndex((lo) => lo.id === lot.id);
    state.currentProgramLots.results.splice(index, 1, lot);

  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
